/* stylelint-disable selector-max-compound-selectors */
//----------------------------------------------------------------------------------------------------------------------
// Theme variables which will customize product styles
//----------------------------------------------------------------------------------------------------------------------
@import 'fonts';
@import 'variables';

@import '/src/assets/scss/main';

.layout.splash-layout {
  background-position: center;

  .global-header {
    border-bottom: 0;
  }
}

/* stylelint-disable-next-line selector-no-qualifying-type */
.publications-section ul li a .item-text span.item-link-label,
a,
.global-footer a,
.task-action .text-link {
  text-underline-offset: 5px;
  text-decoration-color: $klp-color-red;
}

.global-header {
  border-bottom: 1px solid $klp-color-gray;
  padding: 1rem 0;

  .logo {
    padding: 11px 0;

    img {
      width: 90px;
    }
  }
}

.desktop-navigation {
  border-bottom: 1px solid $klp-color-gray;
}

.global-navigation ul li {
  a {
    display: flex;
    justify-content: space-between;
    transition:
      padding-left 0.3s ease-in-out,
      border-bottom-color 0.3s ease-in-out;

    &:hover {
      padding-left: 6px;

      > span {
        text-decoration: underline;
        text-decoration-color: $klp-color-red;
      }

      > * {
        text-decoration: none;
      }
    }

    &::after {
      content: '\f054';
      font-family: 'Font Awesome 6 Pro';
      font-weight: 200;
      font-size: 25px;
      color: $klp-color-red;
      padding-right: 5px;
    }
  }

  .active span {
    text-decoration-color: $klp-color-red;
  }
}

.secondary-navigation {
  a:hover > span:last-child {
    text-decoration-color: $klp-color-red;
  }

  .k-badge-solid-primary {
    background-color: $klp-color-red;
    border-color: $klp-color-red;
  }
}

.btn.btn-primary {
  background-color: $klp-color-red;
  border-color: $klp-color-red;

  &:hover,
  &:focus,
  &:active {
    background-color: $klp-color-red-dark;
    border-color: $klp-color-red;
  }
}

.btn.btn-outline-primary {
  background-color: transparent;
  border-color: $klp-color-primary;
  outline: $klp-color-primary solid 0;

  &:hover,
  &:active {
    background-color: transparent;
    outline-width: 1px;
    color: $klp-color-primary;
  }
}

.global-footer {
  padding-top: 30px;
  padding-bottom: 30px;
}

.portfolios-accordion .k-expander-title {
  font-family: $klp-font-serif;
}

/* stylelint-disable selector-no-qualifying-type */
.formio-form-wrapper {
  .formio-form {
    nav ul.pagination .page-item {
      .page-link {
        color: $primary;
      }

      &.active {
        /* stylelint-disable-next-line max-nesting-depth */
        .page-link {
          background: $primary;
          border-color: $primary;
        }
      }
    }
  }
}
/* stylelint-enable selector-no-qualifying-type */
