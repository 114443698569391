// KLP variables
$klp-color-primary: #3d3d3d;
$klp-color-white: #fff;
$klp-color-red: #e41b65;
$klp-color-gray: #e1e1e1;
$klp-color-light-gray: #f4f4f4;
$klp-color-red-very-light: #fee;
$klp-color-red-dark: #b22852;

$klp-font-serif: 'Lyon', 'Georgia', serif;
$klp-font-sans-serif: 'Calibre', 'Arial', sans-serif;

// Product variables
$ccp-global-header-bkg-color: $klp-color-white;
$ccp-global-header-text-color: $klp-color-primary;
$ccp-global-header-notification-color: $klp-color-red;

$ccp-splash-layout-transparent-header: true;
$ccp-splash-layout-bkg-image: url('../media/employees.jpg');
$ccp-portfolios-section-image: url('../media/employees.jpg');

$ccp-global-footer-bkg-color: $klp-color-light-gray;
$ccp-global-footer-text-color: $klp-color-primary;

// Kendo
$kendo-series-a: $klp-color-red;
$kendo-series-b: $klp-color-primary;
$kendo-series-c: $klp-color-red-dark;
$kendo-series-d: $klp-color-gray;
$kendo-series-e: $klp-color-light-gray;
$kendo-series-f: $klp-color-red-very-light;
$kendo-tabstrip-item-border-radius: 2px;

// Bootstrap
$primary: $klp-color-primary;
$font-size-root: 19px;
$font-family-sans-serif: $klp-font-sans-serif;
$headings-font-family: $klp-font-serif;
$h2-font-size: calc(30 / 19) * 1rem;
$input-btn-padding-y: 10px;
$input-btn-padding-x: 20px;
$border-radius: 2px;
