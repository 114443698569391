@font-face {
  font-family: Lyon;
  src: url('../fonts/klp-Lyon-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Calibre;
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/klp-Calibre-Light.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: Calibre;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/klp-Calibre-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: Calibre;
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/klp-Calibre-RegularItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: Calibre;
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/klp-Calibre-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: Calibre;
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/klp-Calibre-Semibold.woff') format('woff');
  font-display: swap;
}
